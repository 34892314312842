import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">

      <ol>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Contest Details, Concept and Participation: 
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            These terms and conditions apply to the "Parle Hide &amp; Seek Gold
            Fest" program for customers (hereinafter referred to as Contest")
            sponsored and launched by Parle Products Pvt. Ltd, wherein customers
            of Parle Hide &amp; Seek can participate by scanning a QR Code
            printed on the specially marked promo packs of Parle Hide &amp; Seek
            range of products.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The contest is valid PAN India with the exception of the state of
            Tamil Nadu from 01/08/2024 to 05/11/2024 (hereinafter referred as
            "Contest Period").
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            During the Contest Period:
          </p>
        </li>
      </ol>
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            334 winners will be selected to win 1 gram Gold Coin
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            20 winners will be selected to win 10 gram Gold Coin <br />
            <br />
          </p>
        </li>
      </ul>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            All IPR rights including trademark, copyright, and designs used are
            the property of their respective owners.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The Contest is subject to all applicable central, state and local
            laws and regulations.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Customers of Parle Hide &amp; Seek participating or seeking to
            participate in the Contest shall individually be referred to as
            "Participant" and collectively as "Participants".
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or Premier Sales Promotions Pvt Ltd are not
            liable for any guarantee or warranty of the Gold Coins. The
            manufacturer’s warranty or guarantee will apply to the Gold Coins.
            <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
              <strong>

            Agreement to Terms and Conditions: <br />
              </strong>
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            By participating in this contest, Participants fully and
            unconditionally agree to and accept these Terms and Conditions
            available on hideandseekgoldfest.com "Contest Website". The
            decisions of Parle Products Pvt. Ltd will be final and binding in
            all matters related to the Contest. Successfully entering the
            Contest and winning a prize is subject to all requirements set forth
            herein.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd reserves the right to exclude any person
            from the contest on grounds of misconduct or criminal record or for
            any other reasons, as it may deem fit and proper. No communication
            in the regard will be entertained. Parle Products Pvt. Ltd reserves
            the right to terminate, modify or extend the Contest and/or deletion
            or addition of any of the terms and conditions of the Promo, at any
            time at its absolute discretion, without advance notice and/or
            assigning any reason thereof. All decisions of Parle Products Pvt.
            Ltd in respect of the Contest and the prizes therein will be final,
            binding and conclusive. <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Eligibility: <br />
            </strong>
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            This Contest will be open to customers of Parle Products Pvt. Ltd
            who are residents of the India except for the state of Tamil Nadu
            and employees, their immediate family members of Parle Products Pvt.
            Ltd, and the agency.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            This contest is open for adults above 18 years.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or its Agency will not be responsible for
            any NDNC (National Do Not Call) Registry regulation that will come
            into play. All Participants who participate will agree as per the
            terms and conditions that even if they are registered under NDNC,
            DND (Do Not Disturb), Parle Products Pvt. Ltd or its Agency will
            have all the authority to call such shortlisted winners by virtue of
            them having voluntarily participated in the Contest.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>
            Contest Period &amp; Contest Timings: <br />
            </strong>
          </p>
        </li>
      </ol>
      <p dir="ltr" style={{"fontSize":"12px","textAlign":"left"}}>
        The contest will commence from 12:01:01 AM on 01/08/2024 to 11:59:59 PM on
        05/11/2024
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            354 winners will be selected between 12:01:01 AM on 01/08/2024 to
            11:59:59 PM on 05/11/2024 during the contest period.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Time of participation will be defined as the time at which the entry
            is received through the promotional website mode of participation at
            Parle Products Pvt. Ltd.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Entries coming outside the Contest Period will not be considered for
            winner selection.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The contest shall run on the following SKUs of Parle Hide &amp;
            Seek: <br />
            Parle Hide &amp; Seek pack of Rs.30, 100+20g <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Prizes: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            During the Contest Period, winners will be selected for a 1-gram
            gold coin. Therefore, there will be a total of 334 such prizes which
            will be declared during the contest period.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            During the Contest Period, winners will be selected for a 10-gram
            gold coin. Therefore, there will be a total of 20 such prizes which
            will be declared during the contest period. <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            How to Participate: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Any participant can enter any number of times during the contest
            period from a mobile number.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Scan the QR code or visit hideandseekgoldfest.com
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Customer will be asked to complete their registration by entering
            the unique code printed inside the pack, along with their name,
            email ID, mobile number, and consent to T&amp;Cs.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Upon submitting the details, the customers will receive an OTP on
            their registered mobile number. Customers will need to submit the
            OTP to authenticate their mobile number.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Once the customer successfully completes their registration, they
            will stand a chance to win exciting rewards.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd and the Partner Agency shall not be
            responsible for (including but not limited to):
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            For any SPAM generated messages
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            For the Operator Code not being displayed on the user's mobile
            phones
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            For any SMS message delivery failures
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Any lost, late or misdirected computer transmission or network,
            electronic failures or any kind of any failure to receive entries
            owing to transmission failures or due to any technical reason
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If the participant has registered himself to the DND of the telecom
            provider/ the participant has registered with National Do Not Call
            Registry/
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Participant has specifically requested for not receiving messages
            for the specific campaign
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Other conditions beyond its reasonable control
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or Premier Sales Promotions Pvt Ltd shall
            not be responsible for any charges, levies, costs incurred by the
            end consumer to participate in this contest.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Winner Selection: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Any participant can win a max of 1 (one) 1-gram gold coin or 10-gram
            gold coin during the contest period from a mobile number, email ID,
            address, or household.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            From the Valid Entries received, the Organizer will select 354
            winners who will win Prizes throughout the Promotion Period
            (“Winners”)
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Winners will be selected by the Organizers using randomizer software
            irrespective of the time of the entry.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Prizes will be given subject to the winner satisfying the
            verification process of Parle Products Pvt. Ltd
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The Prize cannot be transferred, exchanged or redeemed for cash. In
            the event that the winner does not take the prize in the time and
            manner stipulated, then the prize will be forfeited by the winner
            and cash will not be awarded in lieu of the prize. Prizes must be
            taken as offered and cannot be varied. Any unclaimed prize is
            not-transferable, non-refundable and non-redeemable for other goods
            or services and cannot be taken in exchange for cash.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Any unused prize is not-transferable, non-refundable and
            non-redeemable for other goods or services and cannot be taken in
            exchange for cash.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd shall provide the prize on an "as-is basis"
            and without any warranty or guarantee concerning the quality,
            suitability or comfort, and Parle Products Pvt. Ltd and/or its
            associates, affiliates and/ or its management, directors, officers,
            agents, representatives shall not be responsible for or liable
            (including but not limited to the product and service liabilities)
            for deficiency and/ or defect of any product/service and/or the
            prize or for any kind of consequential damages/ loss, in any manner
            whatsoever. If any Participant has any grievance with respect to the
            prize, he/she may directly contact the manufacturer.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The image of the prize depicted on the press ads/posters/TVC's/pack
            shots etc. are for illustrative purposes only and the actual prize
            may vary from the depiction made.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or its Agencies shall not be liable for any
            loss or damage of the prize due to Act of God, Governmental action,
            Force Majeure circumstances and / or any other reasons which are
            beyond the reasonable control of Parle Products Pvt. Ltd/ its
            Agency, and under no circumstances, Parle Products Pvt. Ltd shall be
            liable to pay any compensation whatsoever for such losses.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Any participant of the contest who wins once during the Contest
            Period shall not be eligible to win any further prizes.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The winner generation for Mega Prizes will be done on the basis of
            Valid Unique mobile number. <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Contacting Winners: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Once the Winners are selected, they will receive a Call confirming
            that they have won a reward with further instructions on how to
            redeem it.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No prize will be awarded if the information presented by the
            Participant(s) at the time of entering the contest is not factually
            correct.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            In the event of death of the Confirmed Winner, no claim from the
            nominee or his/her legal heirs of the Confirmed Winner shall be
            entertained for receiving the prize. <br />
            <br />
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Publicity: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Participant/s undertake and irrevocably and unconditionally
            permit Parle Products Pvt. Ltd to cover the Contest through various
            media including newspapers, radio television news channels,
            internet, point of sale materials, etc., and shall not raise any
            objection, protest or demur to such coverage or in this regard.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The winners must, at the request of Parle Products Pvt. Ltd,
            participate in all promotional activity (such as publicity and
            photography) surrounding the winning of the prize, free of charge,
            and they consent to Parle Products Pvt. Ltd using their name and
            image in promotional material.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            <strong>

            Intellectual Property: <br />
            </strong>
            <br />
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            All right, title and interest, including but not limited to the
            Intellectual Property Rights, in the promotional material(s) and in
            any and all responses received shall vest solely and exclusively
            with Parle Products Pvt. Ltd at all times. Parle Products Pvt. Ltd
            or any person or entity permitted by Parle Products Pvt. Ltd in this
            regard shall be entitled to use the responses received or any
            information in connection with the entry in any media for future
            promotional, marketing, publicity and any other purpose, without any
            permission and or payment to the Participant.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All material submitted in connection with the Contest (whether
            written, audio, electronic or visual form, or a combination of
            those) or any photographs, video and/or film footage and/or audio
            recording taken of Participants are assigned to Parle Products Pvt.
            Ltd upon submission and become the property of Parle Products Pvt.
            Ltd exclusively. Parle Products Pvt. Ltd may use the material in any
            medium in any reasonable manner it sees fit. Copyright in any such
            material remains the sole property of Parle Products Pvt. Ltd <br />
            <br />
          </p>
        </li>
      </ol>
      <h3 dir="ltr">
        General Terms &amp; Conditions of the Contest:
      </h3>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Each Participant represents and warrants that he/she is legally
            competent to enter into binding contracts under applicable laws. By
            taking part and/or entering into the Contest the Participant
            warrants that all information provided by Participant regarding
            Participant's name, age, state, city, address, phone number, etc.,
            is true, correct, accurate and complete.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The decision of Parle Products Pvt. Ltd or its Agency, as regards
            the selection of winners and distribution of the prizes shall be
            final, subject only to Parle Products Pvt. Ltd 's approval. No
            correspondence or any other claims whatsoever, in this regard will
            be entertained.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No queries, claims, dispute, complaints or grievances shall be
            entertained by Parle Products Pvt. Ltd and its agency after 30 days
            from the date of the closure of the contest.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            A participant can win a maximum of 1 prize during the contest
            period. If a participant has won a 1-gram gold coin or 10-gram gold
            coin then the participant will not be eligible to win any further
            prizes.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All applicable taxes will be borne by the winner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Wherever Gift Tax is applicable, the amount will be bourne by the
            customer.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The prize cannot be exchanged and is not transferable and cannot be
            converted into cash.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The Agency shall provide the prize on an "as-is basis" and without
            any warranty or guarantee concerning the quality, suitability or
            comfort, and Parle Products Pvt. Ltd and/or its associates,
            affiliates and/ or its management, directors, officers, agents,
            representatives shall not be responsible for or liable (including
            but not limited to the product and service liabilities) for
            deficiency and/ or defect of any product/service and/or the prize or
            for any kind of consequential damages/ loss, in any manner
            whatsoever. If any Participant has any grievance with respect to the
            prize, he/she may directly contact the manufacturer.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd reserves the right to change, defer, alter
            or cancel this Contest in part or full, or change any or all of the
            terms and conditions that are applicable, without giving prior
            intimation/notice of any kind and will not be liable for any
            consequential losses/damages. Any decision of Parle Products Pvt.
            Ltd in this regard and in general with respect to this Contest and
            the terms and conditions thereto shall be final, binding and
            conclusive.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Nothing contained herein amounts to a commitment or representation
            by Parle Products Pvt. Ltd to conduct further Contests.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The decision of Parle Products Pvt. Ltd shall be final in all
            regards and no communication shall be entertained in this regard.
            None of the provisions of these Terms and Conditions shall be deemed
            to constitute a partnership or agency between any Participant and
            Parle Products Pvt. Ltd and the Participant shall not have the
            authority to bind Parle Products Pvt. Ltd in any manner whatsoever.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or any of its respective
            officers/employees/directors shall not be responsible for delayed,
            lost, mutilated, corrupted or illegible documents etc.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or its Agency shall not be liable to perform
            any of its/their respective obligations under the Contest or in
            respect of the prize where it is unable to do so as a result of
            circumstances beyond its/their control in the nature of fire,
            explosions, natural calamities, state emergency, riots, or any other
            Force Majeure condition, etc., and shall not be liable to compensate
            the Participant in these circumstances.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or it’s agency shall not be
            accountable/liable for any disruptions/stoppages/interruptions or
            cancellation of the Contest due to any government restrictions.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Failure to exercise or delay in exercising a right or remedy
            provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies on the part of
            Parle Products Pvt. Ltd or its Agency.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle Products Pvt. Ltd or its agency accepts no responsibility for
            late, lost, incomplete, incorrectly submitted, delayed, illegible,
            corrupted or misdirected entries, loss of SMS, loss of internet
            connectivity, unique codes, documents, demand drafts, claims or
            correspondence whether due to error, omission, alteration,
            tampering, unauthorized data hacking, deletion, theft, destruction,
            virus attack, transmission interruption, communications failure,
            hardware failure or otherwise. Parle Products Pvt. Ltd or it’s
            agency shall not be liable for any consequences of user error
            including (without limitation) costs incurred.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If a Participant is dissatisfied with the Contest or the Contest
            rules and/or any content or any requirements of the Contest form,
            the materials available related thereto, or with these terms and
            conditions, his/her sole and exclusive remedy is to not participate
            in the Contest.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If a person chooses to access the Contest Website from outside
            India, he/she shall do so on his/her own initiative and shall be
            responsible for compliance with applicable local laws of the
            country.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The operational part of the scheme like delivery of gift shall be
            managed by the Premier Sales Promotions Pvt. Ltd; However, product
            will be distributed through Parle Products Pvt. Ltd
            Distributor/dealer.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The participation in the Contest is purely voluntary and the same
            shall be construed as an acceptance of the terms and conditions
            stipulated herein.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Validity of Prize cannot be extended, OR new Prize cannot be
            provided against the expired/unused Vouchers.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All disputes arising out of or in connection to this scheme are
            subject to exclusive jurisdiction of the courts in Bangalore only.
            <br />
            <br />
          </p>
        </li>
      </ol>
      <p dir="ltr">
        Gold Coin Terms &amp; Conditions
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            All forms of taxes, including gift tax, income tax etc. if
            applicable will be borne by the winner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            There is no cash alternative for the Prize and the same cannot be
            exchanged for cash at any point nor transferred to someone else.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle reserves the right to substitute the Prize offered at any
            point of time with rewards of equal value.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All the costs and expenses for collecting the prize will be borne
            solely by the winner and PARLE INDIA PRIVATE LIMITED or it’s partner
            agency shall not entertain any claim, whatsoever. The winner is
            solely responsible for any other expenses related to the acceptance
            and use of the prize.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No requests or claims shall be entertained by Parle w.r.t. Gold
            Coins.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Parle does not provide any sort of warranty or guarantee with
            respect to Gold Coin. Winner participants understand and acknowledge
            that the Gold Coin has been handed over by Parle on “As Is Where Is”
            basis and as received by Parle.
          </p>
        </li>
      </ol>


    </Popup>
  );
};

export default TermsPopup;
